import React from 'react'

const SvgPlus = props => (
  <svg width={29} height={29} {...props}>
    <path
      fill="#273A41"
      fillRule="evenodd"
      d="M60.5,32 C52.5012361,32 46,38.5012361 46,46.5 C46,54.4987639 52.5012361,61 60.5,61 C68.4987639,61 75,54.4987639 75,46.5 C75,38.5012361 68.4987639,32 60.5,32 Z M60.5,33.6111111 C67.6271528,33.6111111 73.3888889,39.3728472 73.3888889,46.5 C73.3888889,53.6271528 67.6271528,59.3888889 60.5,59.3888889 C53.3728472,59.3888889 47.6111111,53.6271528 47.6111111,46.5 C47.6111111,39.3728472 53.3728472,33.6111111 60.5,33.6111111 Z M60.4118924,37.6263222 C59.9965479,37.6719489 59.6849993,38.0275251 59.6944646,38.4444444 L59.6944646,45.6944444 L52.4444646,45.6944444 C52.419291,45.6928696 52.3941174,45.6928696 52.3689437,45.6944444 C52.1549681,45.7038856 51.9535993,45.7982846 51.8104118,45.9571925 C51.6656615,46.1145256 51.5917156,46.3237847 51.6011567,46.5377564 C51.6105978,46.7517281 51.7049968,46.9531008 51.8639047,47.0962883 C52.0212378,47.2410386 52.2304969,47.3149846 52.4444686,47.3055435 L59.6944686,47.3055435 L59.6944686,54.5555435 C59.6913229,54.771094 59.77471,54.9787824 59.9257517,55.1329657 C60.0783682,55.2855822 60.2844736,55.372115 60.5000322,55.372115 C60.7155908,55.372115 60.9217003,55.2855822 61.0743128,55.1329657 C61.2253544,54.9787783 61.3087415,54.771094 61.3055958,54.5555435 L61.3055958,47.3055435 L68.5555958,47.3055435 C68.7711464,47.3086892 68.9788347,47.2253021 69.1330181,47.0742604 C69.2856346,46.9216439 69.3721674,46.7155385 69.3721674,46.4999799 C69.3721674,46.2844213 69.2856346,46.0783118 69.1330181,45.9256993 C68.9788307,45.7746576 68.7711464,45.6912706 68.5555958,45.6944163 L61.3055958,45.6944163 L61.3055958,38.4444162 C61.3103164,38.2131332 61.2159133,37.9897204 61.0444186,37.8323954 C60.8729239,37.6766332 60.6432157,37.6011124 60.4119367,37.626286 L60.4118924,37.6263222 Z"
      transform="translate(-46 -32)"
    />
  </svg>
)

export default SvgPlus
