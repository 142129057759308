/**
 * Photo Checklist Question Component
 * 
 * An individual photo checklist item. Used in the Checklist Component.
 * Handles the adding/removing of points when a photo is attached to this
 * particular item.
 */
import React from 'react'
import PhotoSelectModal from '../photos/photo-select-modal'
import { isTeamCaptain, getHuntStatus } from '../../utils/team-data'

class PhotoChecklistQuestion extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      checked: false,
      initialized: false,
      photo: null,
    }
    this.handleSelect = this.handleSelect.bind(this)
    this.initialize = this.initialize.bind(this)
  }

  componentDidMount() {
    this.initialize()
  }

  componentDidUpdate() {
    this.initialize()
  }

  initialize() {
    if (!this.state.initialized && this.props.checked !== this.state.checked) {
      this.setState({
        checked: this.props.checked,
        photo: this.props.photo,
        initialized: true,
      })
    }
  }

  handleSelect(photo) {
    console.log(`handle select`)
    console.log(photo)
    const { question, updatePoints } = this.props
    const points = question.answer_value ? question.answer_value : 5
    if (photo !== null && photo !== undefined) {
      this.setState({
        checked: true,
        photo: photo,
      })
      updatePoints(points, { id: question.id, checked: true, photo: photo, points: points })
    } else {
      this.setState({
        checked: false,
        photo: null,
      })
      updatePoints(-points, { id: question.id, checked: false, photo: null, points: points })
    }
  }

  render() {
    const { question } = this.props
    const { photo } = this.state
    const isCaptain = isTeamCaptain()
    const huntStatus = getHuntStatus()
    const canSelect = isCaptain && huntStatus !== `ended`
    let buttonText = `Add Photo`
    if(canSelect){
      buttonText = `Choose Photo`
      if(photo){
        buttonText = `Change Photo`
      }
    }
    return (
      <div className="checklist-item-wrapper">
        <div className="photo-checklist-question mb-3 row no-gutters">
          <div className="col-4 text-center">
            <PhotoSelectModal
              selectedPhoto={photo}
              description={question.description}
              buttonText={buttonText}
              canSelect={canSelect}
              onSelect={this.handleSelect}
            />
          </div>
          <div className={`col-8`}>
            <div className="photo-heading px-3 py-3 text-right header-data">{question.answer_value} pts</div>
            <div className="py-3 px-3" dangerouslySetInnerHTML={{ __html: question.description }} />
          </div>
        </div>
      </div>
    )
  }
}

export default PhotoChecklistQuestion
