/**
 * Checklist Component
 * 
 * Used on the Checklist Page
 * An individual checklist, of either a normal or photo type of checklist.
 * Loads a list of checklist items and displays them.
 */

import React from 'react'
import PropTypes from 'prop-types'
import TransitionGroup from 'react-transition-group/TransitionGroup'
import { CSSTransition } from 'react-transition-group'
import { teamAnswerChecklist, getCurrentTeamData, isTeamCaptain } from '../../utils/team-data'
import ChecklistQuestion from './checklist-question'
import PhotoChecklistQuestion from './photo-checklist-question'
import Link from '../link'
import SvgArrow from '../svg/arrow'
import { getStaticUrl } from '../../utils/helpers'

const checklist_components = {
  photo: PhotoChecklistQuestion,
  text: ChecklistQuestion,
}

class Checklist extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      points: 0,
      totalPoints: 0,
      checked: {},
    }
    this.updatePoints = this.updatePoints.bind(this)
    this.updateSelf = this.updateSelf.bind(this)
    this.getComplete = this.getComplete.bind(this)
    this.getIncomplete = this.getIncomplete.bind(this)
    this.checkComplete = this.checkComplete.bind(this)
    this.toggleOpen = this.toggleOpen.bind(this)
    this.playSuccess = this.playSuccess.bind(this)
  }

  componentDidMount() {
    this.updateSelf()
    this.success_audio = new Audio(this.props.successAudio)
    if (!isTeamCaptain()) {
      this.interval = setInterval(() => this.updateSelf(), 2 * 1000)
    }
  }

  componentWillUnmount() {
    clearInterval(this.interval)
  }

  componentDidUpdate(prevProps) {
    if (this.props.checklist.id !== prevProps.checklist.id) {
      // only update if its changed id
      this.updateSelf()
    }
  }

  toggleOpen() {
    this.setState({ completed_open: !this.state.completed_open })
  }

  updateSelf() {
    const { checklist } = this.props
    const curTeamData = getCurrentTeamData()
    //console.log(curTeamData)
    if (
      curTeamData.checklist_answers !== undefined &&
      curTeamData.checklist_answers !== null &&
      curTeamData.checklist_answers[checklist.id] !== undefined
    ) {
      const prevState = curTeamData.checklist_answers[checklist.id]
      this.setState(prevState)
    } else {
      // set it back to default as there isn't any previous answers
      const defaultState = {
        points: 0,
        totalPoints: 0,
        checked: {},
        completed_open: false,
      }
      this.setState(defaultState)
    }
  }

  playSuccess() {
    if (this.props.soundsEnabled) {
      this.success_audio.play()
    }
  }

  updatePoints(points, question_data) {
    console.log(`updating checklist points`)
    const { checklist } = this.props
    let total_points = this.state.totalPoints + points
    let earned_points = total_points

    // check if this goes over the max score and cap it
    if (checklist.maximum_score && earned_points > checklist.maximum_score) {
      earned_points = checklist.maximum_score
    }
    // check if it's subtracting below zero and make it 0
    if (earned_points < 0) {
      earned_points = 0
    }

    let new_checked = this.state.checked
    new_checked[question_data.id] = question_data

    let newData = {
      points: earned_points,
      totalPoints: total_points,
      checked: new_checked,
    }

    if (points < 0) {
      this.setState(newData)
    } else {
      //delay exit animation
      this.playSuccess()
      this.setState(newData)
    }

    newData.checklist_id = checklist.id
    teamAnswerChecklist(newData)
  }

  checkComplete() {
    console.log(`checking if complete`)
    let incomplete = this.getIncomplete()
    console.log(incomplete)
    if (incomplete.length == 0) {
      console.log(`checklist is complete`)
      this.setState({
        completed_open: true,
      })
    }
  }

  getIncomplete() {
    const { checklist } = this.props
    let incomplete = []
    checklist.questions.map(question => {
      if (this.state.checked[question.id] == undefined || !this.state.checked[question.id].checked) {
        incomplete.push(question)
      }
    })
    return incomplete
  }

  getComplete() {
    const { checklist } = this.props
    let complete = []
    checklist.questions.map(question => {
      if (this.state.checked[question.id] !== undefined && this.state.checked[question.id].checked) {
        complete.push(question)
      }
    })
    return complete
  }

  render() {
    const { checklist } = this.props
    const checklist_type = checklist.type == `photo` ? `photo` : `text`
    const ChecklistElement = checklist_components[checklist_type]
    return (
      <div className="checklist mb-3">
        <div className="site-sub-header px-4 py-2 mb-3">
          <div className="row">
            <div className="col-2 d-flex align-items-center">
              <Link to={`/`} className={`back-arrow`}>
                <SvgArrow />
              </Link>
            </div>
            <div className="col-10 text-right">
              <h3 className="checklist-title title smaller h5 text-uppercase mb-0 d-inline-block">
                {checklist.display_title}
              </h3>
              <span className="points title h5 smaller ml-2">
                ({this.state.points}/{checklist.maximum_score})
              </span>
            </div>
          </div>
          {checklist.description.length > 0 && (
            <p
              className="checklist-description mb-0 mt-3"
              dangerouslySetInnerHTML={{ __html: checklist.description }}
            />
          )}
        </div>

        <div className={`mx-4`}>
          <TransitionGroup enter={true} exit={true} className={`checklist-items`}>
            {checklist.questions.map(question => (
              <CSSTransition key={`checklist-${question.id}`} classNames="checklist-item" timeout={1000}>
                <ChecklistElement
                  question={question}
                  updatePoints={this.updatePoints}
                  checked={this.state.checked[question.id] !== undefined && this.state.checked[question.id].checked}
                  photo={this.state.checked[question.id] !== undefined && this.state.checked[question.id].photo}
                />
              </CSSTransition>
            ))}
          </TransitionGroup>
        </div>
      </div>
    )
  }
}

Checklist.defaultProps = {
  soundsEnabled: true,
  successAudio: getStaticUrl(`/sounds/ding.mp3`),
  checklist: {},
}

Checklist.propTypes = {
  soundsEnabled: PropTypes.bool,
  successAudio: PropTypes.string,
  checklist: PropTypes.object,
}

export default Checklist
