/**
 * Checklist Question Component
 * 
 * An individual Checklist Question, of a normal checklist item.
 * Handles the checking of this box, and adding or removing points based on the
 * checked value.
 * Used in the Checklist Component
 */

import React from 'react'
import { isTeamCaptain, getHuntStatus } from '../../utils/team-data'
import SvgCheck from '../svg/check'

class ChecklistQuestion extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      hunt_status: `pending`,
    }
    this.handleUpdate = this.handleUpdate.bind(this)
  }

  componentDidMount() {
    this.setState({
      hunt_status: getHuntStatus(),
    })
  }

  componentDidUpdate() {}

  handleUpdate() {
    const { question, updatePoints, checked } = this.props
    let new_checked = !checked
    console.log(`Prev: ${checked} New: ${new_checked}`)
    const points = question.answer_value ? question.answer_value : 5
    if (new_checked) {
      updatePoints(points, { id: question.id, checked: new_checked, points: points })
    } else {
      updatePoints(-points, { id: question.id, checked: new_checked, points: points })
    }
  }

  render() {
    const { question, checked } = this.props
    const { hunt_status } = this.state
    const isCaptain = isTeamCaptain()
    const showInput = isCaptain && hunt_status != `ended`
    const checkedClass = checked ? `checked` : ``

    return (
      <div className="checklist-item-wrapper">
        <div className="checklist-item card mb-3">
          <div className="card-header">
            <div className="row">
              {showInput ? (
                <div className="col">
                  <button className={`checkbox ${checkedClass}`} onClick={this.handleUpdate} title="Check">
                    <span className={`svg-container d-flex align-items-center justify-content-center w-100 h-100`}>
                      <SvgCheck />
                    </span>
                  </button>
                </div>
              ) : (
                <div className="col">
                  <div className={`checkbox disabled ${checkedClass}`}>
                    <span className={`svg-container d-flex align-items-center justify-content-center w-100 h-100`}>
                      <SvgCheck />
                    </span>
                  </div>
                </div>
              )}
              <div className="col text-right points">{question.answer_value} pts</div>
            </div>
          </div>
          <div className="card-body" dangerouslySetInnerHTML={{ __html: question.description }} />
        </div>
      </div>
    )
  }
}

export default ChecklistQuestion
