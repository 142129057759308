/**
 * Select a Photo Modal
 * 
 * Prompts users to select a photo from either their camera roll, device,
 * or take a new photo from their camera. Then uploads it to the photo gallery.
 * Optionally also submits it to a photo checklist question as their "answer".
 */

import React from 'react'
import PropTypes from 'prop-types'
import Modal from 'react-bootstrap/Modal'
import { getCurrentTeamData } from '../../utils/team-data'
import TeamPhotoGallery from './team-photo-gallery'
import AppContext from '../../utils/context'

import SvgPlus from '../svg/plus'

import { getStaticUrl, getMediaUrl } from '../../utils/helpers'

class PhotoSelectModal extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      modalShow: false,
      photo: null,
      cameraModalShow: false,
      uploadModalShow: false,
    }
    this.modalClose = this.modalClose.bind(this)
    this.handleSelect = this.handleSelect.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.clearPhoto = this.clearPhoto.bind(this)
    this.showUploadModal = this.showUploadModal.bind(this)
    this.uploadModalClose = this.uploadModalClose.bind(this)
    this.handleUploadSubmit = this.handleUploadSubmit.bind(this)
    this.cameraModalClose = this.cameraModalClose.bind(this)
    this.showCameraModal = this.showCameraModal.bind(this)
    this.handleFileUpdate = this.handleFileUpdate.bind(this)
  }

  componentDidUpdate() {
    const { selectedPhoto } = this.props
    if (
      selectedPhoto &&
      selectedPhoto.id !== undefined &&
      (this.state.photo == null || selectedPhoto.id !== this.state.photo.id)
    ) {
      this.setState({
        photo: selectedPhoto,
      })
    }
  }

  modalClose() {
    this.setState({ modalShow: false })
  }

  uploadModalClose() {
    this.setState({ uploadModalShow: false })
  }

  cameraModalClose() {
    this.setState({ cameraModalShow: false })
  }

  handleSelect(photo) {
    const { canSelect, onSelect } = this.props
    if(canSelect){
      console.log(photo)
      this.setState({
        photo: photo,
      })
    }
  }

  handleSubmit(){
    console.log(`submitting photo to checklist`)
    const { canSelect, onSelect } = this.props
    const { photo } = this.state
    if(canSelect){
      onSelect(photo)
    }
    this.modalClose()
  }

  clearPhoto() {
    const { canSelect, onSelect } = this.props
    this.setState({
      photo: null,
    })
    if(canSelect){
      onSelect(null)
    }
  }

  showUploadModal() {
    this.setState({
      uploadModalShow: true,
      modalShow: false,
      cameraModalShow: false,
    })
  }

  showCameraModal() {
    this.setState({
      cameraModalShow: true,
      modalShow: false,
      uploadModalShow: false,
    })
  }

  handleFileUpdate(event) {
    this.setState({
      modalShow: false,
    })
    this.setUploadModalShow(true, event.target.files[0], this.handleUploadSubmit)
  }

  handleUploadSubmit(res) {
    console.log(`photo uploaded`)
    const photo = res.data
    const { canSelect, onSelect } = this.props
    if(canSelect){
      this.setState({
        photo: photo,
      })
    }
    this.setState({
      modalShow: true,
    })
    this.uploadModalClose()

  }

  render() {
    const { photo, modalShow } = this.state
    const { canSelect, buttonText, selectedPhoto } = this.props
    const team = getCurrentTeamData()
    return (
      <AppContext.Consumer>
        {({ setUploadModalShow }) => {
          this.setUploadModalShow = setUploadModalShow
          return (
            <>
              <div className="photo-button-wrapper">
                <button className="btn btn-upload-photo" onClick={() => this.setState({ modalShow: true })} title="Select Photo">
                  {selectedPhoto ? (
                    <div
                      className="img-preview"
                      style={{
                        backgroundImage: `url(${getMediaUrl(selectedPhoto.image)})`,
                      }}></div>
                  ) : (
                    <div className="">
                      <SvgPlus />
                      <p className={`text-uppercase add-photo mt-2 text-primary`}>{buttonText}</p>
                    </div>
                  )}
                </button>
                {selectedPhoto && canSelect && (
                  <button className="btn-clear-photo" onClick={this.clearPhoto} title="Remove">
                    &times;
                  </button>
                )}
              </div>
              <Modal
                show={modalShow}
                onHide={this.modalClose}
                centered
                className="fullscreen-modal photo-upload-modal light-modal">
                <Modal.Header>
                  <div className="col-6">
                    <h3 className={`title h5 text-uppercase`}>Select a Photo</h3>
                  </div>
                  <div className="col-6 text-right">
                    <button className="btn btn-tiny text-primary py-0" onClick={this.modalClose}>
                      Back
                    </button>
                  </div>
                </Modal.Header>
                <Modal.Body className="px-0 py-0">
                  <TeamPhotoGallery team_id={team.id} access_code={team.access_code} selectedPhoto={photo} onClick={this.handleSelect} />
                </Modal.Body>
                <Modal.Footer className="bg-white">
                  <label className={`file-input-label btn btn-block btn-success mb-3`}>
                    <input
                      type="file"
                      name="image"
                      accept="image/png, image/jpeg"
                      className={`form-control-file`}
                      //value={this.state.image}
                      onChange={this.handleFileUpdate}
                      placeholder="Base64 Image"
                      required
                    />
                    Add Photo
                  </label>
                  {canSelect &&
                    <button className="btn btn-block btn-danger" disabled={photo ? false : true} onClick={this.handleSubmit}>
                      Submit Photo
                    </button>
                  }
                </Modal.Footer>
              </Modal>
            </>
          )
        }}
      </AppContext.Consumer>
    )
  }
}

PhotoSelectModal.defaultProps = {
  buttonText: `Add Photo`,
  onSelect: () => {},
}

PhotoSelectModal.propTypes = {
  buttonText: PropTypes.string,
  onSelect: PropTypes.func,
  selectedPhoto: PropTypes.object,
  canSelect: PropTypes.bool,
}

export default PhotoSelectModal
