/**
 * Checklist Page
 * 
 * Displays the checklist portion of the hunt, if it exists.
 * Checklists can be either a normal checklist, or a photo checklist.
 */

import React from 'react'

import LoggedInWrapper from '../components/loggedinWrapper'

import Checklist from '../components/checklist/checklist'
import { navigate } from '@reach/router'
import { getCurrentTeamData, getHuntStatus } from '../utils/team-data'
import CompleteModal from '../components/complete-modal'
import AppContext from '../utils/context'
import { getCurrentHuntData } from '../utils/hunt-data'
import { getStaticUrl } from '../utils/helpers'

export default class ChecklistsPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      data: {},
      play_sounds: true,
      success_sound: getStaticUrl(`/sounds/ding.mp3`),
      modalShow: false,
      modalDismissed: false,
      checklist: null,
      hunt_status: getHuntStatus(),
    }
    this.modalClose = this.modalClose.bind(this)
    this.goToChecklist = this.goToChecklist.bind(this)
    this.update = this.update.bind(this)
    this.checkComplete = this.checkComplete.bind(this)
    this.isComplete = this.isComplete.bind(this)
  }

  componentDidMount() {
    let data = getCurrentHuntData()
    this.setState({ data: data })
    if (this.state.hunt_status != `started`) {
      navigate(`/`)
    }
    let success_sound = data.hunt.sound_success ? data.hunt.sound_success.file : getStaticUrl(`/sounds/ding.mp3`)
    let play_sounds = data.hunt.play_sounds == 1
    this.setState({ play_sounds: play_sounds, success_sound: success_sound })
    this.update()
    this.interval = setInterval(() => this.checkComplete(), 3 * 1000)
    this.setLoading(false)
  }

  componentWillUnmount() {
    clearInterval(this.interval)
  }

  modalClose() {
    clearInterval(this.interval)
    this.setState({
      modalShow: false,
      modalDismissed: true,
    })
  }

  isComplete(checklist) {
    const teamData = getCurrentTeamData()
    if (
      teamData.checklist_answers !== undefined &&
      teamData.checklist_answers !== null &&
      teamData.checklist_answers[checklist.id] !== undefined
    ) {
      const answers = teamData.checklist_answers[checklist.id]
      if (answers.totalPoints == checklist.maximum_score) {
        //console.log('completed checklist!')
        return true
      }
    }
    return false
  }

  checkComplete() {
    const { checklist } = this.state
    if (this.isComplete(checklist)) {
      this.setState({ modalShow: true })
    }
  }

  update() {
    const data = getCurrentHuntData()
    this.setState({ data: data })
    let checklist = null
    let checklist_id = this.props.location.hash.substring(1)
    if (data.checklists !== undefined) {
      data.checklists.map(item => {
        if (item.id == checklist_id) {
          checklist = item
          return false
        }
      })
      this.setState({ checklist: checklist })
    }
  }

  goToChecklist(id) {
    this.setState({
      modalShow: false,
      modalDismissed: false,
    })
    navigate(`/checklists/#${id}`)
  }

  render() {
    const { checklist, play_sounds, success_sound } = this.state

    return (
      <AppContext.Consumer>
        {({ setLoading }) => {
          this.setLoading = setLoading
          return (
            <LoggedInWrapper>
              <h1 className="sr-only">Checklist</h1>
              {checklist !== null && (
                <Checklist checklist={checklist} soundsEnabled={play_sounds} successAudio={success_sound} />
              )}

              <CompleteModal
                modalShow={this.state.modalShow}
                modalDismissed={this.state.modalDismissed}
                modalClose={this.modalClose}
              />
            </LoggedInWrapper>
          )
        }}
      </AppContext.Consumer>
    )
  }
}
